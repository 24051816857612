import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":""}},[_c(VCard,[_c(VCardText,[_c('crud',{attrs:{"model":'Worker',"create-permission":"Agregar Trabajadores","edit-permission":"Editar Trabajadores","delete-permission":"Eliminar Trabajadores","resource":"workers","load":"entidad,cliente","show-clone":"","bulk-actions":""},scopedSlots:_vm._u([{key:"extra-items-menu",fn:function({ item }){return [_c(VListItem,{directives:[{name:"can",rawName:"v-can",value:('Ver Trabajadores'),expression:"'Ver Trabajadores'"}],attrs:{"to":`/workers/${item.id}`}},[_c(VListItemTitle,[_vm._v(_vm._s(_vm._f("trans")("view_resource"))+" "+_vm._s(_vm._f("trans")("literals.worker")))]),_c(VListItemAction,[_c(VIcon,[_vm._v("mdi-eye")])],1)],1)]}},{key:"extra-item-buttons",fn:function({ item }){return [_c(VBtn,{directives:[{name:"can",rawName:"v-can",value:('Ver Trabajadores'),expression:"'Ver Trabajadores'"}],staticClass:"mr-1",attrs:{"x-small":"","depressed":"","color":"green","dark":"","to":`/workers/${item.id}`}},[_vm._v(" "+_vm._s(_vm._f("trans")("view_resource"))+" ")])]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }